import React, { useRef } from "react";
import {
    Box, Flex, Button, Stack, 
    // Menu,
    // MenuButton,
    MenuList, MenuItem 
} from "@chakra-ui/react";
import { Link } from "gatsby"
import { graphql } from 'gatsby'
import { FetchInjuries } from '../../hooks/fetch-injuries'
import { MegaMenu } from 'primereact/menu';
import { Dropdown } from 'react-bootstrap'

import Logo from "./logo";

const NavBar = (props) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <NavBarContainer px="20px" {...props}>
            <Logo
                w={{ base: "150px", md: "200px" }}
                color={["white", "white", "primary.500", "primary.500"]}
            />
            <MenuToggle toggle={toggle} isOpen={isOpen} />
            <MenuDropdowns isOpen={isOpen} />
        </NavBarContainer>
    );
};

const CloseIcon = () => (
    <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <title>Close</title>
        <path
            fill="black"
            d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
        />
    </svg> 
);

const MenuIcon = () => (
    <svg
        width="24px"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
        fill="black"
    >
        <title>Menu</title>
        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
    </svg>
);

const MenuToggle = ({ toggle, isOpen }) => {
    return (
        <Box display={{ base: "block", md: "none" }} onClick={toggle}>
            {isOpen ? <CloseIcon /> : <MenuIcon />}
        </Box>
    );
};

const CustomMenuItem = ({ children, isLast, to = "/", ...rest }) => {
    return (
        <Link to={to}>
            <Button bg="white" border="2px solid #F3F3F3" className="menuItem" display="block" {...rest}>
                {children}
            </Button>
        </Link>
    );
};


const ExternalMenuItem = ({ children, isLast, to, target = "_self", border = "2px solid #F3F3F3",  ...rest}) => {
    return (
        <a href={to} target={target}>
            <Button bg="white" border={border} className="menuItem" display="block" {...rest}>
                {children}
            </Button>
        </a>
    );
}

// const DropDownItemTest = ({ children, active, setActive , color, title }) => {
//     return(
//         <Box position="relative">
//             <Button bg={color} onClick={setActive}>{title}</Button>
//             {active &&
//                 <Box className="dropdown-menu">
//                     {children}
//                 </Box>
//             }
//         </Box>
//     );
// }

const MenuDropdowns = ({ isOpen }) => {

    const nodes = FetchInjuries();

    let items = [
        { label: 'New'},
        { label: 'Delete'}
    ];

    const menu = useRef(null);

    return(
        <Box
            display={{ base: isOpen ? "block" : "none", md: "block" }}
            flexBasis={{ base: "100%", md: "auto" }}
        >
            <Stack
                
                align="center"
                justify={["center", "space-between", "flex-end", "flex-end"]}
                direction={{base: "column",md: "row"}}
                pt={[4, 4, 0, 0]}
                spacing="16px"
            >
                <CustomMenuItem to="/about" bg="#14c0e1" color="white" border="#14c0e1" className="aboutCarewayBtn" >About Careway</CustomMenuItem>
                {/* <Menu autoSelect="false" modifiers={[{
                    name: 'offset', options: { offset: [0,0] } }]}>
                    <MenuButton as={Button} bg="#ff7e21" color="white" border="#ff7e21" className="myInjuryBtn" >
                        My Injury
                    </MenuButton>
                    <MenuList zIndex={2}>
                        {nodes.map((element, index) => {
                            return (<MenuItem><Link to={"/injury/"+element.slug} style={{color: "black"}}>{element.title}</Link></MenuItem>)
                        })}
                    </MenuList>
                </Menu> */}
                <Dropdown>
                    <Dropdown.Toggle as={Button} bg="#ff7e21" color="white" border="#ff7e21" variant="success" className="myInjuryBtn" id="dropdown-basic">
                        My Injury
                    </Dropdown.Toggle>    
                    <div className="navSpacer" />
                    <Dropdown.Menu className="myInjuryDropDown" style={{ backgroundColor: "#f89249"}}>
                        {nodes.map((element, index) => {
                            return (<Dropdown.Item as={Link} to={"/injury/" + element.slug} style={{ color: "white" }}>{element.title}</Dropdown.Item>)
                        })}
                    </Dropdown.Menu>
                </Dropdown>
                <ExternalMenuItem to="/pdf/Careway-Consent-Form.pdf" target="_blank">Consent Form </ExternalMenuItem>
                <CustomMenuItem to="/providers">Find a Provider </CustomMenuItem>
                <ExternalMenuItem to="https://hub.careway.co.nz" bg="#E4E5E5" className="providerLoginBtn"  >Provider Login </ExternalMenuItem>
            </Stack>
        </Box>
    );
}


const NavBarContainer = ({ children, ...props }) => {
    return (
        <Flex
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            w="100%"
            py={"25px"}
            bg={["primary.500", "primary.500", "transparent", "transparent"]}
            color={["white", "white", "primary.700", "primary.700"]}
            maxWidth="1269px"
            mx="auto"
            {...props}
        >
            {children}
        </Flex>
    );
};


export default NavBar;

