import React from "react"
import { Link } from "gatsby"
import "../scss/main.scss"
import Header from "./header/header"
// import { TransitionState } from "gatsby-plugin-transition-link"



const Layout = ({ isHomePage, children }) => {

    return (
        <div id="page" className="site">
            {/* <a className="skip-link screen-reader-text" href="#content"></a> */}
            <div className="container">
                <Header />
            </div>

            {/* <TransitionState> */}
                <div id="content" className="site-content">
                    <div id="primary" className="content-area">
                        <main id="main" className="site-main" role="main">{children}</main>
                    </div>
                </div>
            {/* </TransitionState> */}

	        <footer className="banner" role="contentinfo">
                <div className="sitefooter">
                    <ul className="footer-nav">
                        <li><Link to="/">HOME</Link></li>
                        <li><Link to="/about">ABOUT CAREWAY</Link></li>
                        {/* <li><a href="#">KNEE - ACL</a></li>
                        <li><a href="#">KNEE - MENISCAL</a></li>
                        <li><a href="#">SHOULDER</a></li>
                        <li><a href="#">SPINE</a></li> */}
                        <li><a href="/pdf/Careway-Consent-Form.pdf" target="_blank" rel="noreferrer">CONSENT FORM</a></li>
                        <li><a href="https://hub.careway.co.nz">PROVIDER LOGIN</a></li>
                    </ul>
                    <div className="logo-section">
                        <Link to="/">
                            <img src="/img/Careway-LogoHorizontal-Rev.svg" alt="Careway Logo" />
                        </Link>
                    </div>
                    <ul className="tcsfooter">
                        <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                        <li><Link to="/terms-and-conditions">Terms & Conditions</Link></li>
                    </ul>
                    <ul className="copyrightFooter">
                        <li>Copyright 2020</li>
                        <li>Website development by <a href="http://www.spawn.co.nz/" target="_blank" rel="noreferrer">Spawn Advertising</a></li>
                    </ul>
                </div>
            </footer>
            {/* <script src="/js/bootstrap.min.js"> </script> */}

        </div>
    )
}

export default Layout
