import React from "react"
import { Box, Image } from "@chakra-ui/react"
import { Link } from "gatsby"

export default function Logo(props) {
    return (
        <Link to="/">
            <Box {...props}>
                <Image src="/img/Careway-LogoHorizontal-RGB.svg" alt="Careway Logo"/>
            </Box>
        </Link>
    )
}