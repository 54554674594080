import { useStaticQuery, graphql } from "gatsby"

export const FetchInjuries = () => {
    const { allWpPublicinjury } = useStaticQuery(
        graphql`
      query PublicInjuries {
        allWpPublicinjury {
            nodes {
                title
                slug
            }
        }
    }
    `
    )
    return allWpPublicinjury.nodes
}   