// custom typefaces
import 'bootstrap/dist/css/bootstrap.min.css';
import "typeface-montserrat"
import "typeface-merriweather"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
// import "./src/style2.css"


import { wrapPageElement as wrap } from "./src/chakra-wrapper"


export const wrapPageElement = wrap